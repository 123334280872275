<template>
  <v-container v-if="!loading">
    <form @submit.prevent="onUpdateProfile">
      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            name="name"
            label="Full Name"
            prepend-icon="person"
            id="name"
            class="pr-1"
            :rules="[rules.required]"
            v-model="name"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            :label="$t('Common.handle')"
            prepend-inner-icon="alternate_email"
            append-outer-icon="fas fa-user-check"
            @click:prepend-inner="fillHandle"
            @click:append-outer="checkHandleExists"
            :error="handleExists"
            :error-messages="handleMessages"
            :hint="$t('Common.handle_hint')"
            @change="checkHandleExists"
            :placeholder="tagLabel"
            v-model.trim.lazy="handle"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="4">
          <v-text-field
            name="phone"
            label="Primary Phone"
            id="phone"
            prepend-icon="phone"
            v-model="phone"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-text-field
            name="email"
            label="Email"
            id="email"
            :rules="[rules.email]"
            prepend-icon="email"
            v-model="email"
            :readonly="user.emailVerified"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-icon class="pt-3 green--text" v-if="user.emailVerified">check</v-icon>
          <template v-if="!user.emailVerified">
            <small class="mt-3 red--text text-xs-left">
              Email is NOT verified
              <v-spacer></v-spacer>
              <router-link to="#" flat small @click.native="sendVerificationEmail" color="blue">
                Send verification email
                <v-icon small>fas fa-envelope</v-icon>
              </router-link>
            </small>
          </template>
        </v-col>
      </v-row>
      <v-row class="my-4">
        <v-col cols="12" sm="8" class="px-1">
          <v-expansion-panels>
            <v-expansion-panel>
              <v-expansion-panel-header>Social Settings</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="px-2">
                  <v-text-field
                    name="facebook"
                    label="Facebook"
                    id="facebook"
                    prepend-icon="fab fa-facebook"
                    v-model="facebook"
                  ></v-text-field>
                </v-card>
                <v-card class="px-2">
                  <v-text-field
                    name="twitter"
                    label="Twitter"
                    id="twitter"
                    prepend-icon="fab fa-twitter"
                    v-model="twitter"
                  ></v-text-field>
                </v-card>

                <v-card class="px-2">
                  <v-text-field
                    name="instagram"
                    label="Instagram"
                    id="instagram"
                    prepend-icon="fab fa-instagram"
                    v-model="instagram"
                  ></v-text-field>
                </v-card>
                <v-card class="px-2">
                  <v-text-field
                    name="pinterest"
                    label="Interest"
                    id="pinterest"
                    prepend-icon="fab fa-pinterest"
                    v-model="pinterest"
                  ></v-text-field>
                </v-card>
                <v-card class="px-2">
                  <v-text-field
                    name="yelp"
                    label="Yelp"
                    id="yelp"
                    prepend-icon="fab fa-yelp"
                    v-model="yelp"
                  ></v-text-field>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <template>
        <v-row>
          <v-col cols="12" sm="3"></v-col>
          <v-col cols="12" v-if="false" sm="3">
            <v-btn raised class="primary" @click="onPickFile">Upload Profile Picture</v-btn>
            <input
              type="file"
              style="display: none"
              ref="fileInput"
              accept="image/*"
              @change="onFilePicked"
            />
          </v-col>
          <v-row>
            <v-col cols="12" sm="3"></v-col>
          </v-row>
          <v-col cols="12" sm="7" v-if="chefApprovalStatus === 'approved'">
            <v-switch
              v-model="profileStatus"
              value="hide"
              @change="showHideProfile"
              :label="
                `Hide your profile? ${
                  profileStatus === 'hide'
                    ? `Also make sure hide your products`
                    : ``
                }`
              "
              color="red"
            ></v-switch>
          </v-col>
        </v-row>

        <v-row v-if="profilePicture">
          <v-col cols="12" sm="7" class="my-2 mx-2">
            <img :src="profilePicture" height="200" />
          </v-col>
        </v-row>

        <v-row align="center">
          <v-btn class="primary ma-2" :disabled="!formIsValid" type="submit">Save</v-btn>
          <v-dialog v-model="showAvatarChangeDlg" fullscreen>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" v-show="profile">
                Upload Profile Picture
                <v-icon right>camera</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="headline grey lighten-2" primary-title>Upload Profile Picture</v-card-title>
              <v-card-text class="d-flex">
                <media-uploader @uploadMedia="uploadAvatar" :ratio="1/1" uploadType="user" />
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="showAvatarChangeDlg = false">Back</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </form>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { profileMixin } from 'fod-core'

export default {
  mixins: [profileMixin],
  components: {
    MediaUploader: () =>
      import('fod-core/src/components/common/MediaUploader.vue')
  },
  data() {
    return {
      showAvatarChangeDlg: false,
      name: '',
      handle: null,
      handleExists: false,
      handleMessages: '',
      businessName: '',
      businessLogo: '',
      description: '',
      email: '',
      phone: '',
      website: '',
      twitter: '',
      facebook: '',
      instagram: '',
      yelp: '',
      pinterest: '',
      profileStatus: 'public',
      profilePicture: '',
      avatar: null,
      image: null,
      rules: {
        required: value => !!value || 'Required.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        }
      }
    }
  },
  mounted() {
    let profile = this.profile

    if (profile != null) {
      this.name = profile.name
      this.handle = profile.handle
      this.description = profile.description
      this.businessName = profile.businessName
      this.businessLogo = profile.businessLogo
      this.email = profile.email
      this.profileStatus = profile.profileStatus
      this.website = profile.website
      this.facebook = profile.facebook
      this.twitter = profile.twitter
      this.instagram = profile.instagram
      this.yelp = profile.yelp
      this.pinterest = profile.pinterest

      this.phone = profile.phone
      this.avatar = profile.avatar
    }
  },
  computed: {
    ...mapGetters({
      location: 'userLocation',
      loading: 'loading',
      user: 'user',
      isChef: 'isChef',
      chefApprovalStatus: 'chefApprovalStatus',
      profile: 'profile'
    }),
    formIsValid() {
      return this.name !== '' && !this.handleExists && this.handle !== '' && this.handle && this.phone !== '' && this.phone
    },
    getTagFromName() {
      return this.name
        ? this.name
            .trim()
            .replace(/\s+/g, '-')
            .toLowerCase()
        : null
    },
    tagLabel() {
      let tag = this.getTagFromName

      return `${tag} `
    }
  },

  methods: {
    uploadAvatar(file) {      
      this.$store.dispatch('uploadUserAvatar', {
        file: file
      }).then(() => {
        this.$store.dispatch('loadProfile')
      })
      
    },
    onUpdateProfile() {
      let profile = {
        name: this.name,
        businessName: this.businessName,
        businessLogo: this.businessLogo,
        profileStatus: this.profileStatus || 'public',
        email: this.email,
        phone: this.phone,
        handle: this.handle,
        website: this.website,
        facebook: this.facebook,
        pinterest: this.pinterest,
        instagram: this.instagram,
        yelp: this.yelp,
        twitter: this.twitter,
        description: this.description,
        image: this.image,
        type: this.profile.type,
        avatar: this.avatar
      }
      this.$store.dispatch('saveProfile', profile)
      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('loadProfile')
    },
    showHideProfile() {
      console.log('Setting profile status to :', this.profileStatus)

      this.$store.dispatch('updateProfile', {
        profileStatus: this.profileStatus || 'public'
      })
    },
    fillHandle() {
      if (!this.handle || this.handle.length == 0) {
        this.handle = this.getTagFromName
      }
      return true
    },

    checkHandleExists() {
      if (this.handle && this.handle.length > 1) {
        //this.handle = this.getTagFromName

        console.log(`Checking is handle ${this.handle} exists`)
        this.$store.dispatch('checkHandle', this.handle).then(data => {
          if (data) {
            this.handleExists = true
            this.handleMessages = [
              "This handle it's not available or has been used by others"
            ]
          } else {
            this.handleExists = false
            this.handleMessages = []
          }
          console.log('Handle check result', data)
        })
      }
      return true
    }
  }
}
</script>
<style scoped>
</style>
