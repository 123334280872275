<template>
  <v-container fluid v-if="!loading && profile">
    <div v-if="isChef">
      <chef-forward url="/my/profile" />
    </div>
    <div v-else>
      <template>
        <approval-message />
        <v-row class="mb-2">
          <v-col cols="12" sm="6">
            <h4>Profile {{ profileName }}</h4>
          </v-col>
          <v-spacer></v-spacer>
          <v-menu bottom origin="center center">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showDeleteAccount = true">
                <v-list-item-title>
                  Delete Account
                  <v-icon color="red">delete</v-icon>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-row>
          <v-col class="ms" cols="12">
            <v-tabs
              color="white"
              background-color="blue-grey darken-3"
              slider-color="yellow"
              grow
              show-arrows
              v-model="activeTab"
            >
              <v-tab href="#tab-basic" class="white--text" value="tab-basic">My Profile</v-tab>
              <v-tab href="#tab-address" class="white--text" value="tab-address">
                My Address
                <v-icon
                  v-if="!profile.addresses || profile.addresses.length == 0"
                  color="yellow lighten-3 "
                  class="mx-2"
                >error</v-icon>
              </v-tab>

              <v-tab-item value="tab-basic">
                <profile-basic></profile-basic>
              </v-tab-item>
              <v-tab-item value="tab-address">
                <profile-address></profile-address>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </template>
    </div>
    <v-dialog v-model="showDeleteAccount" width="60%">
      <v-card>
        <v-card-title class="grey lighten-4 py-4">
          <v-icon color="red">block</v-icon>Your profile and all your data will
          be deleted and this is not reversible!!!!
        </v-card-title>
        <v-card-actions>
          <v-btn color="secondary" @click="goodbye">Delete</v-btn>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="showDeleteAccount = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <pagination-alert
      ref="paginationAlert"
      heading="Warning"
      message="Are you sure you want to leave this page without saving contents?"
    ></pagination-alert>
  </v-container>
</template>
<script>
import ProfileBasic from './ProfileBasic'
import ApprovalMessage from './ApprovalMessage'
import ChefForward from '@/components/common/ChefForward'

import { mapGetters } from 'vuex'

export default {
  components: {
    'profile-basic': ProfileBasic,
    //'profile-permits': ProfilePermits,
    //'work-experience': SubWorkExperience,
    ChefForward,
    //ProfileAddress,
    ProfileAddress: () =>
      import('fod-core/src/components/profile/ProfileAddress.vue'),
    ApprovalMessage,
    PaginationAlert: () =>
      import('fod-core/src/components/common/PaginationAlert.vue')
  },
  data() {
    return {
      fab: false,
      activeTab: 'tab-basic',
      showDeleteAccount: false
    }
  },
  // beforeRouteLeave(to, from, next) {
    // this.$refs.paginationAlert.openDialog()
    // this.$nextTick(() => {
    //   this.$refs.paginationAlert.$once('onYes', () => {
    //     next(true)
    //   })
    //   this.$refs.paginationAlert.$once('onCancel', () => {
    //     next(false)
    //   })
    // })
  // },
  computed: {
    ...mapGetters({
      profile: 'profile',

      hasAddress: 'hasAddress',
      isChef: 'isChef',
      loading: 'loading'
    }),
    profileName() {
      if (this.profile) {
        let name = this.profile.name
        if (this.profile.user) {
          name += ' (username: ' + this.profile.user + ')'
        }

        return name
      }
      return ''
    }
  },
  mounted() {
    this.$gtm.trackView('Profile', 'currentpath')
    if (this.$router.history.current.params.view_mode === 'address') {
      this.activeTab = 'tab-address'
    } else {
      this.activeTab = 'tab-basic'
    }
  },
  methods: {
    goodbye() {
      this.$store.dispatch('deleteAccount')
      this.showDeleteAccount = false
      this.$router.push('/')
    }
  }
}
</script>
